import { lazy } from 'react'

export const IconMinusCircle = lazy(() =>
  import(
    /* webpackChunkName: "veneer-icon-1" */ '@veneer/core/dist/scripts/icons/icon_minus_circle'
  )
)

export const IconDashboard = lazy(() =>
  import(
    /* webpackChunkName: "veneer-icon-2" */ '@veneer/core/dist/scripts/icons/icon_dashboard_application'
  )
)

export const IconInkDrop = lazy(() =>
  import(
    /* webpackChunkName: "veneer-icon-3" */ '@veneer/core/dist/scripts/icons/icon_ink_drop_checkmark_circle'
  )
)

export const IconCloud = lazy(() =>
  import(
    /* webpackChunkName: "veneer-icon-4" */ '@veneer/core/dist/scripts/icons/icon_cloud'
  )
)

export const IconEnvelope = lazy(() =>
  import(
    /* webpackChunkName: "veneer-icon-5" */ '@veneer/core/dist/scripts/icons/icon_envelope'
  )
)

export const IconBell = lazy(() =>
  import(
    /* webpackChunkName: "veneer-icon-6" */ '@veneer/core/dist/scripts/icons/icon_bell'
  )
)

export const LogoHp = lazy(() =>
  import(
    /* webpackChunkName: "veneer-logo-1" */ '@veneer/core/dist/esm/scripts/logos/hp/hp'
  )
)

export const LogoHpPlus = lazy(() =>
  import(
    /* webpackChunkName: "veneer-logo-2" */ '@veneer/core/dist/esm/scripts/logos/hp_plus/hp_plus'
  )
)
